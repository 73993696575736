import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { toast } from 'react-toastify'

import { useBranding } from 'utils/useBranding'
import { ResourceModel } from 'api/data-contracts'
import { ConfirmPopup } from 'components/moduleSlides/ConfirmPopup'
import { FormTextField } from 'components/FormTextField'
import { ActionButton } from 'components/ActionButton'
import ImageDragAndDrop from 'components/companySettings/ImageDragAndDrop'
import { Fileprops } from 'utils/models/File'

// Constants
import { FILE, URL } from 'utils/constants/resources'

// API - custom hooks
import {
  useGetResource,
  useUpdateResource,
  useUpdateResourceFile,
} from 'utils/resource'
import { ResourceLink } from 'components/resources/ResourceLink'

const validationSchema = Yup.object<ResourceModel>().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  resourceType: Yup.string(),
  url: Yup.string().when('ResourceType', (ResourceType, schema) =>
    ResourceType.toString() === URL
      ? schema.required('Url is required')
      : schema.notRequired(),
  ),
})

const Resource = () => {
  const { branding } = useBranding()

  const { resourceId } = useParams()
  const { resource: existingResource } = useGetResource(resourceId!)
  const { mutateAsync: updateResource } = useUpdateResource()
  const { mutateAsync: updateResourceFile } = useUpdateResourceFile()

  const navigate = useNavigate()
  const { courseId } = useParams()
  const [showConfirm, setShowConfirm] = useState(false)
  const [file, setFile] = useState<Fileprops | null>(null)

  const onCancel = () => {
    navigate(`/settings/course-setup/resources/${courseId}`)
    setShowConfirm(false)
  }

  const onCancelHandler = () => {
    if (!watch('name') && !watch('description')) {
      navigate(`/settings/course-setup/resources/${courseId}`)
      setShowConfirm(false)
    } else {
      setShowConfirm(true)
    }
  }

  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm<ResourceModel>({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    setValue('name', existingResource?.name)
    setValue('description', existingResource?.description)
    setValue('resourceType', existingResource?.resourceType)
    setValue('url', existingResource?.url)
  }, [existingResource, setValue])

  const onSubmit: SubmitHandler<ResourceModel> = async data => {
    const resource: {
      name: string | undefined
      description: string | undefined
      url?: string | undefined
    } = {
      name: data.name!,
      description: data.description!,
    }

    const resourceFileType: {
      File?: File
    } = {}

    if (existingResource?.resourceType === URL) {
      resource.url = data.url!
    }

    if (existingResource?.resourceType === FILE) {
      resourceFileType.File = file as File
    }

    try {
      await updateResource({
        resourceId: resourceId as string,
        data: resource,
      })

      resourceFileType?.File !== null &&
        existingResource?.resourceType === FILE &&
        (await updateResourceFile({
          resourceId: resourceId as string,
          data: resourceFileType,
        }))

      reset()
      navigate(`/settings/course-setup/resources/${courseId}`)
      toast.success('Resource updated')
    } catch {
      toast.error('Unable to update resource')
    }
  }

  return (
    <>
      <h1>Edit Resource</h1>
      <form>
        <div className="flex flex-wrap justify-between">
          <FormTextField
            control={control}
            errors={errors?.name}
            name="name"
            label="Name"
          />
          <FormTextField
            control={control}
            errors={errors?.description}
            name="description"
            label="Description"
            multilineValue={2}
          />

          {existingResource && existingResource?.resourceType === URL && (
            <FormTextField
              control={control}
              errors={errors?.url}
              name="url"
              label="URL"
            />
          )}
          {existingResource && existingResource?.resourceType === FILE && (
            <div className="w-full desktop-w-50 mb-25">
              <div className="mb-10">
                <p className="text-label">File</p>
                <ResourceLink resource={existingResource} />
              </div>

              <ImageDragAndDrop
                hidePreview
                imageFile={file}
                setImageFile={setFile}
                imageExist={null}
                deleteButton={true}
                fileTypes={['JPG', 'PNG', 'GIF', 'SVG', 'PDF']}
                label="Upload or drop a file here"
              />
            </div>
          )}
        </div>
      </form>

      <ActionButton
        name="Save changes"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={handleSubmit(onSubmit)}
      />
      <ActionButton
        name="Cancel"
        background={branding.stylingSecondary ?? 'orange'}
        setAction={onCancelHandler}
      />
      {showConfirm && (
        <ConfirmPopup
          title="Confirm cancel"
          message="Are you sure you want to stop creating this resource?"
          onConfirm={onCancel}
          open={showConfirm}
          onCancel={() => setShowConfirm(false)}
        />
      )}
    </>
  )
}
export default Resource
