import { useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import { FormTextField } from 'components/FormTextField'
import { useBranding } from 'utils/useBranding'

// API
import { ActionButton } from 'components/ActionButton'
import {
  UserModuleDataModel,
  UserModuleFeedbackModel,
} from 'api/data-contracts'
import { useGiveFeedback } from 'utils/module'

type Props = {
  userCourseInstanceId: string
  moduleId: string
  currentModuleData: UserModuleDataModel
}

const ModuleFeedback = (props: Props) => {
  const { branding } = useBranding()
  const { mutateAsync: giveFeedback, isLoading } = useGiveFeedback()

  const ValidationSchema = Yup.object<UserModuleFeedbackModel>().shape({
    moduleFeedback: Yup.string().required('feedback is required'),
  })

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<UserModuleFeedbackModel>({
    resolver: yupResolver(ValidationSchema),
  })

  useEffect(() => {
    reset({
      moduleFeedback: props.currentModuleData?.moduleFeedback,
    })
  }, [reset, props.currentModuleData?.moduleFeedback])

  const onSubmit: SubmitHandler<UserModuleFeedbackModel> = async data => {
    try {
      await giveFeedback({
        moduleId: props.moduleId!,
        data: {
          userCourseInstanceId: props.userCourseInstanceId,
          moduleFeedback: data.moduleFeedback!,
        },
      })
      toast.success('Feedback updated!')
    } catch {
      toast.error('Feedback send failed')
    }
  }

  return (
    <form className="mb-50">
      <div className="flex flex-wrap justify-between">
        <FormTextField
          control={control}
          errors={errors?.moduleFeedback}
          name="moduleFeedback"
          label=""
          multilineValue={8}
          length={true}
        />
      </div>
      <ActionButton
        name="Send"
        background={branding.stylingPrimary ?? 'blue'}
        setAction={handleSubmit(onSubmit)}
        spinner={isLoading}
      />
    </form>
  )
}

export default ModuleFeedback
